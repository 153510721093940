import React, { useState, useEffect } from "react";
import { Switch, Route, Link, useHistory, useLocation } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";

// https://codesandbox.io/s/6khtm?file=/demo.js
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import CloudDownload from "@material-ui/icons/CloudDownload";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import MyLocationIcon from "@material-ui/icons/MyLocation";
import GroupIcon from "@material-ui/icons/Group";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import HomeIcon from "@material-ui/icons/Home";

import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import BuildIcon from "@material-ui/icons/Build";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import RedditIcon from '@material-ui/icons/Reddit';

import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";

import Badge from "@material-ui/core/Badge";
import FastfoodIcon from "@material-ui/icons/Fastfood";

import Modal from "@material-ui/core/Modal";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Button from "@material-ui/core/Button";


import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  badge: {
    "& > .MuiBadge-badge": {
      right: theme.spacing(1),
      border: `2px solid ${theme.palette.background.paper}`,
      transform: `scale(0.8) translate(100%, 50%)`,
    },
    "& > .MuiBadge-invisible": {
      transform: `scale(0) translate(100%, 50%)`,
    },
  },
  donationBadge: {
    "& > .MuiBadge-badge": {
      background: `linear-gradient(to right, ${theme.palette.primary.main} 22%, ${theme.palette.secondary.main} 22%)`,
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
  },
}));

const BurgerNav = (props) => {
  let currentPath = useLocation()?.pathname;
  const history = useHistory(); 
  const classes = useStyles();

  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [locationMenuOpen, setLocationMenuOpen] = React.useState(null);

  const handleOpenLocationMenu = (event) => {
    setLocationMenuOpen(event.currentTarget);
  };

  const handleCloseLocationMenu = () => {
    setLocationMenuOpen(null);
    if (!['/all-burgers', '/my-favourites', '/my-group-favourites'].includes(currentPath)){
      history.push('/all-burgers')
    }
    handleDrawerClose();
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { window } = props;
  const HideOnScroll = (props) => {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  };

  const drawer = (
    <>
      <List dense={true}>
        <ListItem
          button
          key={`burgerburgerburger`}
          component={Link}
          to="/"
          onClick={handleDrawerClose}
          selected={(currentPath === '/')}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={`burgerburgerburger`} />
        </ListItem>
      </List>
      <Divider />
      <List dense={true}>
        <ListItem
          button
          key={`near me`}
          onClick={() => {
            props.setPreferences((curPreferences) => {
              let newPreferences = { ...curPreferences };
              newPreferences["sortBy"] = "distance";
              newPreferences["location"] = "near me";
              return newPreferences;
            });
            // console.log(props.location.pathname)
            if (!['/all-burgers', '/my-favourites', '/my-group-favourites'].includes(currentPath)){
              history.push('/all-burgers')
            }
            handleDrawerClose();
          }}
          selected={(props.preferences?.sortBy === "distance") && (props.preferences?.location === "near me")}
        >
          <ListItemIcon>
            <MyLocationIcon />
          </ListItemIcon>
          <ListItemText primary={`near me`} />
        </ListItem>
        <ListItem
          button
          key={`near somewhere else`}
          onClick={handleOpenLocationMenu}
          selected={(props.preferences?.sortBy === "distance") && (props.preferences?.location !== "near me")}
        >
          <ListItemIcon>
            <EditLocationIcon />
          </ListItemIcon>
          <ListItemText primary={`near somewhere else`} />
        </ListItem>
        <ListItem
          button
          key={`cheapest first`}
          onClick={() => {
            props.setPreferences((curPreferences) => {
              let newPreferences = { ...curPreferences };
              newPreferences["sortBy"] = "price";
              return newPreferences;
            });
            if (!['/all-burgers', '/my-favourites', '/my-group-favourites'].includes(currentPath)){
              history.push('/all-burgers')
            }
            handleDrawerClose();
          }}
          selected={(props.preferences?.sortBy === "price")}
        >
          <ListItemIcon>
            $$$
          </ListItemIcon>
          <ListItemText primary={`cheapest first`} />
        </ListItem>
      </List>
      <Divider />
      <List dense={true}>
        <ListItem
          button
          key={`burger selection tool`}
          component={Link}
          to="/bst"
          onClick={handleDrawerClose}
          selected={(currentPath === '/bst') || (currentPath === '/filtered-burgers')}
        >
          <ListItemIcon>
            <Badge
              className={classes.badge}
              badgeContent={props.filteredCount}
              max={999}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              BST
            </Badge>
          </ListItemIcon>
          <ListItemText primary={`burger selection tool`} />
        </ListItem>
        <ListItem
          button
          key={`all burgers`}
          component={Link}
          to="/all-burgers"
          onClick={() => {
            handleDrawerClose();
          }}
          selected={(currentPath === '/all-burgers')}
        >
          <ListItemIcon>
            <Badge
              className={classes.badge}
              badgeContent={props.totalCount}
              max={999}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <FastfoodIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={`all ${props.totalCount} burgers`} />
        </ListItem>
        <ListItem
          button
          key={`my favourites`}
          component={Link}
          to="/my-favourites"
          onClick={() => {
            handleDrawerClose();
          }}
          selected={(currentPath === '/my-favourites')}
        >
          <ListItemIcon>
            <Badge
              className={classes.badge}
              badgeContent={
                props.favourites.filter(
                  (favourite) => favourite && favourite.isFavourite
                ).length
              }
              max={999}
              color="secondary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <FavoriteIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={`my favourites`} />
        </ListItem>
        <ListItem
          button
          key={`my group favourites`}
          component={Link}
          to="/my-group-favourites"
          onClick={() => {
            handleDrawerClose();
          }}
          selected={(currentPath === '/my-group-favourites')}
        >
          <ListItemIcon>
            <Badge
              className={classes.badge}
              badgeContent={props.groupFavouriteBurgersLength}
              max={999}
              color="secondary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <GroupIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={`my group favourites`} />
        </ListItem>
      </List>
      <Divider />
      <List dense={true}>
        <ListItem
          button
          key={`share favourites`}
          component={Link}
          to="/invite"
          onClick={handleDrawerClose}
          selected={(currentPath === '/invite')}
        >
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText primary={`share favourites`} />
        </ListItem>
        <ListItem
          button
          key={`download CSV file`}
          component={Link}
          to="/download"
          onClick={handleDrawerClose}
          selected={(currentPath === '/download')}
        >
          <ListItemIcon>
            <CloudDownload />
          </ListItemIcon>
          <ListItemText primary={`download CSV file`} />
        </ListItem>
      </List>
      <Divider />
      <List dense={true}>
        <ListItem
          button
          key={`settings`}
          component={Link}
          to="/settings"
          onClick={handleDrawerClose}
          selected={(currentPath === '/settings')}
        >
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary={`settings`} />
        </ListItem>
      </List>
      <Divider />
      <List dense={true}>
        <ListItem
          button
          key={`support kaibosh`}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://givealittle.co.nz/fundraiser/burgerburgerburgercom-for-kaibosh-2023"
          onClick={handleDrawerClose}
        >
          <ListItemIcon>
            <Badge
              className={`${classes.badge} ${classes.donationBadge}`}
              badgeContent={`198`}
              max={9999}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MonetizationOnIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText
            primary={`support kaibosh`}
          />
        </ListItem>
        <ListItem
          button
          key={`say something nice`}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          // href="https://www.linkedin.com/posts/oleg-voronin-ab4aa5117_for-people-outside-of-wellington-im-delighted-activity-6828418027818762241-nQY1"
          href="https://www.linkedin.com/in/oleg-voronin-ab4aa5117/"
          onClick={handleDrawerClose}
        >
          <ListItemIcon>
            <LinkedInIcon />
          </ListItemIcon>
          <ListItemText primary={`say something nice`} />
        </ListItem>
        <ListItem
          button
          key={`make a suggestion`}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.reddit.com/r/Wellington/comments/152ya11/burgerburgerburger_thing_for_finding_woap_burgers/"
          onClick={handleDrawerClose}
        >
          <ListItemIcon>
            <RedditIcon />
          </ListItemIcon>
          <ListItemText primary={`make a suggestion`} />
        </ListItem>
      </List>
      <Divider />
      <List dense={true}>
        <ListItem disabled>
          <ListItemText
            primary={`❤️ Archie ❤️ Ella ❤️ Marisa ❤️`}
          />
        </ListItem>
      </List>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <CssBaseline />
      {/* <HideOnScroll {...props}> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            burgerburgerburger
          </Typography>

          <Modal
            open={!!locationMenuOpen}
            onClose={handleCloseLocationMenu}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Card>
              <CardContent>
                {Object.keys(props.locations).map((locationKey) => (
                  <Button
                    key={locationKey}
                    onClick={() => {
                      props.setMyCoords(props.locations[locationKey]);
                      props.setPreferences((curPreferences) => {
                        let newPreferences = { ...curPreferences };
                        newPreferences["sortBy"] = "distance";
                        newPreferences["location"] = "somewhere else";
                        return newPreferences;
                      });
                      handleCloseLocationMenu();
                    }}
                  >
                    {locationKey}
                  </Button>
                ))}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const customCoords = prompt('you can get coordinates right clicking or tapping on google maps', '-41.28473822232275, 174.77746977553346')
                    if(!customCoords) {
                      return;
                    }
                    const [lat, lon] = customCoords.replace(/[()]/g, '').split(",");
                    props.setMyCoords([lat, lon]);
                    props.setPreferences((curPreferences) => {
                      let newPreferences = { ...curPreferences };
                      newPreferences["sortBy"] = "distance";
                      newPreferences["location"] = "somewhere else";
                      return newPreferences;
                    });
                    handleCloseLocationMenu();
                  }}
                >
                  or set custom coordinates
                </Button>
              </CardContent>
            </Card>
          </Modal>
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export default BurgerNav;

import React from "react";
import { Link } from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from "@material-ui/icons/ExpandMore";

const BurgerSelectionTool = ({ preferences, setPreferences }) => {
  
  const [favouriteValue, setFavouriteValue] = React.useState('everything');
  const [dietaryValue, setDietaryValue] = React.useState({
    dairyFree: preferences.dairyFree,
    glutenFree: preferences.glutenFree,
    nutFree: preferences.nutFree,
    vegan: preferences.vegan,
    vegetarian: preferences.vegetarian,
  });
  const [proteinValue, setProteinValue] = React.useState({
    alternative: preferences.alternative,
    beef: preferences.beef,
    chicken: preferences.chicken,
    duck: preferences.duck,
    fish: preferences.fish,
    lamb: preferences.lamb,
    other: preferences.other,
    pork: preferences.pork,
    seafood: preferences.seafood,
    sweet: preferences.sweet,
    veggie: preferences.veggie,
    venison: preferences.venison,
  });
  const [venueValue, setVenueValue] = React.useState({
    wheelchairAccessible: preferences.wheelchairAccessible,
    okWithSharing: preferences.okWithSharing,
    allowsBookings: preferences.allowsBookings,
  });
  
  const [openDietary, setOpenDietary] = React.useState(true);

  const handleOpenDietaryClick = () => {
    setOpenDietary(!openDietary);
  };
  const [openProtein, setOpenProtein] = React.useState(false);

  const handleOpenProteinClick = () => {
    setOpenProtein(!openProtein);
  };

  const handleFavouriteChange = (event) => {
    setFavouriteValue(event.target.value);
  };
  const handleDietaryChange = (event) => {
    setDietaryValue({ ...dietaryValue, [event.target.name]: event.target.checked });
  };
  const handleProteinChange = (event) => {
    setProteinValue({ ...proteinValue, [event.target.name]: event.target.checked });
  };
  const handleVenueChange = (event) => {
    setVenueValue({ ...venueValue, [event.target.name]: event.target.checked });
  };

  const applyFilters = (event) => {
    setPreferences((curPreferences) => {
      let newPreferences = { ...curPreferences, ...dietaryValue, ...proteinValue, ...venueValue, showOnly: favouriteValue };
      return newPreferences;
    });
  };

  const clearFilters = (event) => {
    setPreferences((curPreferences) => {
      let newPreferences = { };
      return newPreferences;
    });
  };

  const handleChange = (event) => {
    setPreferences((curPreferences) => {
      let newPreferences = { ...curPreferences };
      newPreferences[event.target.name] = !curPreferences[event.target.name];
      return newPreferences;
    });
  };
  const handleLocationChange = (event) => {
    setPreferences((curPreferences) => {
      let newPreferences = { ...curPreferences };
      newPreferences[event.target.name] = event.target.value;
      return newPreferences;
    });
  };


  const handleDietaryToggle = (value) => (event) => {
    setDietaryValue({ ...dietaryValue, [value]: !dietaryValue[value] });
  };
  const handleProteinToggle = (value) => (event) => {
    setProteinValue({ ...proteinValue, [value]: !proteinValue[value] });
  };
  const handleVenueToggle = (value) => (event) => {
    setVenueValue({ ...venueValue, [value]: !venueValue[value] });
  };

  return (
    <Card>
      <CardContent>
        <CardHeader
          title={`👋👨‍🍳🍔`}
          subheader={`hello welcome to burgerburgerburger may I take your order?`}
        />

        <List>
          <ListItem button onClick={handleOpenDietaryClick}>
            <ListItemText primary="Dietary requirements" />
            {openDietary ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDietary} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {[
                {
                  title: "Dairy free",
                  value: "dairyFree",
                },
                {
                  title: "Gluten free",
                  value: "glutenFree",
                },
                {
                  title: "Nut free",
                  value: "nutFree",
                },
                {
                  title: "Vegan",
                  value: "vegan",
                },
                {
                  title: "Vegetarian",
                  value: "vegetarian",
                },
              ].map(({ title, value }) => {
                const labelId = `dietary-label-${value}`;

                return (
                  <ListItem
                    key={value}
                    role={undefined}
                    dense
                    button
                    onClick={handleDietaryToggle(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        checked={dietaryValue[value]}
                        name={value}
                        onChange={handleDietaryChange}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={title} />
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
          <ListItem button onClick={handleOpenProteinClick}>
            <ListItemText
              primary={
                <>
                  Burger protein{" "}
                  <span style={{ opacity: 0.5 }}>
                    (
                    {Object.keys(proteinValue).filter(
                      (key) => !!proteinValue[key]
                    ).length || "any"}{" "}
                    of {Object.keys(proteinValue).length})
                  </span>
                </>
              }
            />
            {openProtein ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openProtein} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {[
                {
                  title: "Alternative meat",
                  value: "alternative",
                },
                {
                  title: "Beef",
                  value: "beef",
                },
                {
                  title: "Chicken",
                  value: "chicken",
                },
                {
                  title: "Duck",
                  value: "duck",
                },
                {
                  title: "Fish",
                  value: "fish",
                },
                {
                  title: "Lamb",
                  value: "lamb",
                },
                {
                  title: "Other",
                  value: "other",
                },
                {
                  title: "Pork",
                  value: "pork",
                },
                {
                  title: "Seafood",
                  value: "seafood",
                },
                {
                  title: "Sweet/Dessert",
                  value: "sweet",
                },
                {
                  title: "Veggie",
                  value: "veggie",
                },
                {
                  title: "Venison",
                  value: "venison",
                },
              ].map(({ title, value }) => {
                const labelId = `protein-label-${value}`;

                return (
                  <ListItem
                    key={value}
                    role={undefined}
                    dense
                    button
                    onClick={handleProteinToggle(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        checked={proteinValue[value]}
                        name={value}
                        onChange={handleProteinChange}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={title} />
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
          <ListItem>
            <ListItemText primary="Venue requirements" />
          </ListItem>
          <List component="div" disablePadding>
            {[
              {
                title: "Wheelchair accessible",
                value: "wheelchairAccessible",
              },
              // {
              //   title: "OK with sharing burgers",
              //   value: "okWithSharing",
              // },
              // {
              //   title: "Allows bookings",
              //   value: "allowsBookings",
              // },
            ].map(({ title, value }) => {
              const labelId = `venue-label-${value}`;

              return (
                <ListItem
                  key={value}
                  role={undefined}
                  dense
                  button
                  onClick={handleVenueToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      checked={venueValue[value]}
                      name={value}
                      onChange={handleVenueChange}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={title} />
                </ListItem>
              );
            })}
          </List>
          <ListItem>
            <ListItemText primary="Favourites" />
          </ListItem>
          <RadioGroup
            aria-label="favourites"
            name="favourites-filter"
            value={favouriteValue}
            onChange={handleFavouriteChange}
          >
            <List component="div" disablePadding>
              <ListItem dense button variant="body2">
                <FormControlLabel
                  value="not-yet-rated"
                  control={<Radio />}
                  label="Only not yet rated burgers"
                  variant="body2"
                />
              </ListItem>
              <ListItem dense button variant="body2">
                <FormControlLabel
                  value="favourites"
                  control={<Radio />}
                  label="Only favourite burgers"
                  variant="body2"
                />
              </ListItem>
              <ListItem dense button>
                <FormControlLabel
                  value="group-favourites"
                  // disabled={true}
                  control={<Radio />}
                  label="Only group favourite burgers"
                />
              </ListItem>
              <ListItem dense button>
                <FormControlLabel
                  value="everything"
                  control={<Radio />}
                  label="Everything"
                />
              </ListItem>
            </List>
          </RadioGroup>
        </List>

        <Box m={2}>
          <FormGroup>
            <Button
              variant="contained"
              color="secondary"
              onClick={applyFilters}
              component={Link}
              to="/filtered-burgers"
            >
              Apply filters
            </Button>
          </FormGroup>
        </Box>
        <Box m={2}>
          <FormGroup>
            <Button
              color="secondary"
              onClick={clearFilters}
              component={Link}
              to="/filtered-burgers"
            >
              Clear filters
            </Button>
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BurgerSelectionTool;

/* eslint-disable */
import React, { forwardRef, useState, useEffect, useRef, useLayoutEffect } from "react";

import ReactGA from 'react-ga4';

import {
  Switch,
  Route,
  Redirect,
  Link,
  useLocation,
  useParams,
} from "react-router-dom";

import "./App.css";
// import { venues as venuesData } from "./2022-woap-burger.json";
// import { data } from "./2023-woap-burger-graphql-details.json";
import { data } from "./2023-woap-burger.json";

// import { data as data0 } from "./2023-woap-burger-graphql-details-introspected-skip-0.json";
// import { data as data20 } from "./2023-woap-burger-graphql-details-introspected-skip-20.json";
// import { data as data40 } from "./2023-woap-burger-graphql-details-introspected-skip-40.json";
// import { data as data60 } from "./2023-woap-burger-graphql-details-introspected-skip-60.json";
// import { data as data80 } from "./2023-woap-burger-graphql-details-introspected-skip-80.json";
// import { data as data100 } from "./2023-woap-burger-graphql-details-introspected-skip-100.json";
// import { data as data120 } from "./2023-woap-burger-graphql-details-introspected-skip-120.json";
// import { data as data140 } from "./2023-woap-burger-graphql-details-introspected-skip-140.json";
// import { data as data160 } from "./2023-woap-burger-graphql-details-introspected-skip-160.json";
// import { data as data180 } from "./2023-woap-burger-graphql-details-introspected-skip-180.json";

// console.log(JSON.stringify({data:{allBurgers:[
//   ...data0.allBurgers,
//   ...data20.allBurgers,
//   ...data40.allBurgers,
//   ...data60.allBurgers,
//   ...data80.allBurgers,
//   ...data100.allBurgers,
//   ...data120.allBurgers,
//   ...data140.allBurgers,
//   ...data160.allBurgers,
//   ...data180.allBurgers,
// ]}},null,2))

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

import { FixedSizeList, FixedSizeGrid } from "react-window";

import AutoSizer from "react-virtualized-auto-sizer";


import {CopyToClipboard} from 'react-copy-to-clipboard';

import BurgerCard from "./BurgerCard";
import BurgerNav from "./Nav";
import BurgerSelectionTool from "./BurgerSelectionTool";

import {emojiAlphabet, favouritesToString, favouritesFromString} from "./emojiAlphabet.js"


const allBurgers = data.allBurgers;

const TRACKING_ID = "G-SMHBV4YDL7";
ReactGA.initialize(TRACKING_ID);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  listWrapper: {
    height: `calc(100vh - ${theme.spacing(8)}px)`,
  },
  logoAvatar: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    // opacity: 0.5,
  },
}));

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const groupFavouritesToString = (groupFavouritesObject) => {
  if (groupFavouritesObject) {
    // console.log(favouritesObject);
    return Object.keys(groupFavouritesObject)
      ?.map((key) => {
        return `${key}=${favouritesToString(groupFavouritesObject[key])}`;
      })
      .join("&");
  } else {
    return "";
  }
};

const groupFavouritesFromString = (groupFavouritesString) => {
  let parsedGroupFavourites = {};
  if (groupFavouritesString) {
    groupFavouritesString.split("&").forEach((kvp) => {
      const [friendName, friendFavouritesString] = kvp.split("=");
      parsedGroupFavourites[friendName] = favouritesFromString(
        friendFavouritesString
      );
    });
  }
  // console.log("parsedGroupFavourites", parsedGroupFavourites);
  return parsedGroupFavourites;
};

const attemptToReadStoredName = () => {
  const myName = localStorage.getItem("bbbName") || '';
  // console.log(myName);
  return myName;
};

const attemptToReadStoredFavourites = () => {
  const myFavouritesString = localStorage.getItem("bbbMyFavourites");
  let myFavouritesParsed
  try {
    myFavouritesParsed = JSON.parse(myFavouritesString) || Array(206).fill(undefined)
  } catch(err) {
    myFavouritesParsed = Array(206).fill(undefined)
  }
  return myFavouritesParsed
  // console.log(myFavouritesString);
  return (
    (myFavouritesString && favouritesFromString(myFavouritesString)) ||
    Array(206).fill(undefined)
  );
};

const attemptToReadStoredGroupFavourites = () => {
  const myGroupFavouritesString = localStorage.getItem("bbbGroupFavourites");
  // console.log(myFavouritesString);v
  // const tempGroupFav = myGroupFavouritesString &&
  // groupFavouritesFromString(myGroupFavouritesString);
  const tempGroupFav = myGroupFavouritesString && JSON.parse(myGroupFavouritesString);
  // console.log(tempGroupFav);
  return tempGroupFav;
};

const ReceiveInvite = ({ setFavourites, setGroupFavourites, groupFavourites, myNameSlug }) => {
  let query = useQuery();

  const [pendingNewGroupFavourites, setPendingNewGroupFavourites] = useState([]);
  const [pendingFriends, setPendingFriends] = useState([]);
  const [selfMergeConflict, setSelfMergeConflict] = useState(false);
  const [friendsMergeConflict, setFriendsMergeConflict] = useState([]);
  const [selfMergeConflictResolveUsingMine, setSelfMergeConflictResolveUsingMine] = useState('using-mine');
  const [friendsMergeConflictResolveUsingMine, setFriendsMergeConflictResolveUsingMine] = useState('using-theirs');

  const handleSelfMergeConflictResolveUsingMine = (event) => {
    setSelfMergeConflictResolveUsingMine(event.target.value);
  };
  const handleFriendsMergeConflictResolveUsingMine = (event) => {
    setFriendsMergeConflictResolveUsingMine(event.target.value);
  };

  const saveGroupFavourites = () => {

    let mergedGroupFavourites = { ...pendingNewGroupFavourites };

    if ((friendsMergeConflictResolveUsingMine === 'using-mine') ){
      mergedGroupFavourites = {
        ...mergedGroupFavourites,
        ...groupFavourites,
      };
    } else {
      mergedGroupFavourites = {
        ...groupFavourites,
        ...mergedGroupFavourites,
      };
    }
    

    if (mergedGroupFavourites?.hasOwnProperty(myNameSlug)){
      if (selfMergeConflictResolveUsingMine === 'using-theirs'){
        setFavourites(mergedGroupFavourites[myNameSlug]);
      }
    }
    
    setGroupFavourites(mergedGroupFavourites);
  };
  const ignoreGroupFavourites = () => {};

  useEffect(() => {
    let tempNewGroupFavourites = {};

    for (const [key, value] of query.entries()) {
      tempNewGroupFavourites[key] = favouritesFromString(value);
    }
    if (
      pendingNewGroupFavourites !== null &&
      tempNewGroupFavourites !== null &&
      JSON.stringify(pendingNewGroupFavourites) !==
        JSON.stringify(tempNewGroupFavourites)
    ) {
      setPendingNewGroupFavourites(tempNewGroupFavourites);
    }

    const newSelfMergeConflict = Object.keys(tempNewGroupFavourites).indexOf(myNameSlug) !== -1;
    setSelfMergeConflict(newSelfMergeConflict);

    const newPendingFriends = Object.keys(tempNewGroupFavourites).filter(key => key !== myNameSlug);
    if (pendingFriends !== null &&
      newPendingFriends !== null &&
      JSON.stringify(pendingFriends) !== JSON.stringify(newPendingFriends)){
      setPendingFriends(newPendingFriends);
    }
    
    const newFriendsMergeConflict = (newPendingFriends !== null) && (groupFavourites !== null) && newPendingFriends?.filter(friend => Object.keys(groupFavourites).includes(friend));
    if (friendsMergeConflict !== null &&
      newFriendsMergeConflict !== null &&
      JSON.stringify(friendsMergeConflict) !== JSON.stringify(newFriendsMergeConflict)){
      setFriendsMergeConflict(newFriendsMergeConflict);
    }
  }, [query]);


  return (
    <>
      <Card>
        <CardContent>
          <FormGroup>
            <FormControl component="fieldset">
              <Box m={2}>
                <FormLabel component="legend">
                  {pendingFriends.join(", ") || 'someone'} shared their favourites
                  with you!
                </FormLabel>
              </Box>

              {selfMergeConflict && (
                <Box m={2}>
                  <FormGroup>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Your group favourites already include an entry for you,{" "}
                        {myNameSlug}
                      </FormLabel>
                      <RadioGroup
                        aria-label="favourites"
                        name="favourites-filter"
                        value={selfMergeConflictResolveUsingMine}
                        onChange={handleSelfMergeConflictResolveUsingMine}
                      >
                        <FormControlLabel
                          value="using-mine"
                          control={<Radio />}
                          label="Keep mine"
                        />
                        <FormControlLabel
                          value="using-theirs"
                          control={<Radio />}
                          label="Use favourites shared with me"
                        />
                      </RadioGroup>
                    </FormControl>
                  </FormGroup>
                </Box>
              )}

              {friendsMergeConflict.length > 0 && (
                <Box m={2}>
                  <FormGroup>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Your group favourites already include entries for{" "}
                        {friendsMergeConflict.join(", ")}
                      </FormLabel>
                      <RadioGroup
                        aria-label="favourites"
                        name="favourites-filter"
                        value={friendsMergeConflictResolveUsingMine}
                        onChange={handleFriendsMergeConflictResolveUsingMine}
                      >
                        <FormControlLabel
                          value="using-mine"
                          control={<Radio />}
                          label="Keep mine"
                        />
                        <FormControlLabel
                          value="using-theirs"
                          control={<Radio />}
                          label="Use favourites shared with me"
                        />
                      </RadioGroup>
                    </FormControl>
                  </FormGroup>
                </Box>
              )}

              <Box m={2}>
                <FormGroup>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={saveGroupFavourites}
                    component={Link}
                    to="/my-group-favourites"
                  >
                    Import group favourites
                  </Button>
                </FormGroup>
              </Box>
              <Box m={2}>
                <FormGroup>
                  <Button
                    color="secondary"
                    onClick={ignoreGroupFavourites}
                    component={Link}
                    to="/my-group-favourites"
                  >
                    Ignore
                  </Button>
                </FormGroup>
              </Box>
            </FormControl>
          </FormGroup>
        </CardContent>
      </Card>
      {/* </Box> */}
    </>
  );
};

const LOCATIONS = {
  "📍 beehive": [-41.278432448678, 174.77675648774482],
  "📍 bucket fountain": [-41.292247777856616, 174.77631509138317],
  "📍 cable car": [-41.284247705420135, 174.77511430100205],
  "📍 stadium": [-41.27413483302871, 174.78541541028494],
  "📍 war memorial": [-41.298990843753316, 174.77715132615384],
  "📍 te papa": [-41.2904643406396, 174.78208939899116],
  "📍 zealandia": [-41.29060182376409, 174.7532855191339],
  "📍 zoo": [-41.319527897632824, 174.7846232354341],

  "🌐 brooklyn": [-41.305178711437485, 174.76309139431694],
  "🌐 cbd": [-41.284023631061494, 174.77656112795424],
  "🌐 island bay": [-41.33682241961465, 174.77210568012407],
  "🌐 karori": [-41.28478472413149, 174.73614982454632],
  "🌐 lyall bay": [-41.32883687518135, 174.795704873513],
  "🌐 lower hutt": [-41.21289328104631, 174.9014949756415],
  "🌐 masterton": [-40.94538353665182, 175.66690424558672],
  "🌐 miramar": [-41.31613404134625, 174.81667068316358],
  "🌐 newtown": [-41.31331930594876, 174.77939813551097],
  "🌐 porirua": [-41.13824867161819, 174.8456889181941],
  "🌐 upper hutt": [-41.124488411852, 175.06699657503626],
  
  "⭐ bus stop outside onesushi kilbirnie": [-41.31778693154806, 174.79480779040486],
  "⭐ coffee cart in post office square": [-41.2850456, 174.7752241],
  "⭐ little penang on victoria but they are packed": [-41.2920735, 174.772239],
  "⭐ whitcouls elevator between lambton and terrace": [-41.2831157, 174.7724416],
};

// console.log("allBurgers", allBurgers);

// // make it playable with in the console repl
// window.venuesWithBurgers = venuesWithBurgers; //ReferenceError: Cannot access 'window' before initialization

// what burger event keys/values are there? there's a lot and I don't know what to care about
const burgerKeys = Object.keys(allBurgers[0]);

// sanity check that all burger events have the same shape...
// const burgerKeys = [...new Set(venuesWithBurgers.map(venue => Object.keys(venue.Burger)).flat())];
// didn't have to do this, they're all the same shape :)

// what buger values are there?
// const burgerKeyValues = Object.assign({}, ...burgerKeys.map(burgerKey => ({
//   [burgerKey]: [...new Set(venuesWithBurgers.map(venue => venue.Burger[burgerKey]))],
// })));
// apparently sometimes values are arrays themselves, so wanna flatten them
const burgerKeyValues = Object.assign(
  {},
  ...burgerKeys.map((burgerKey) => ({
    [burgerKey]: [
      ...new Set(
        allBurgers.map((burger) => burger[burgerKey]).flat()
      ),
    ],
  }))
);
// console.log(burgerKeyValues);

// what venue event keys/values are there? there's a lot and I don't know what to care about
// const venueKeys = Object.keys(venuesWithBurgers[0].Venue);
// actually this time some venues have more keys than others, glad I checked
const venueKeys = [
  ...new Set(allBurgers.map((burger) => Object.keys(burger.venue)).flat()),
];
const venueKeyValues = Object.assign(
  {},
  ...venueKeys.map((venueKey) => ({
    [venueKey]: [
      ...new Set(
        allBurgers.map((venue) => venue[venueKey]).flat()
      ),
    ],
  }))
);
// console.log(venueKeyValues);

const DIETARY_MAPPINGS = {
  "Gluten Free": "GF",
  "Gluten Free_possible": "GF*",
  "Dairy Free": "DF*",
  "Dairy Free_possible": "DF*",
  "Nut Free": "NF",
  "Nut Free_possible": "NF*",
  Vegan_possible: "VV*",
  Vegan: "VV",
  Vegetarian: "V",
  Vegetarian_possible: "V*",
};

// ok now I think I know what burger keys/values I care about, ready to commit to a shape
const ALL_BURGERS_SEED = allBurgers.map((burger, index) => {
  return {
    bbbidx: index,
    // distance: distanceFunction(venue.Venue.ll),
    name: burger.name,
    // 🛑 title: burger.Burger.title,
    burgerDescription: burger.description,
    price: burger.price,//2021: burger_price || 13, //everybody eats
    
    beerMatchPrice: burger.beerMatchPrice,
    beerMatch: burger.beerMatch,

    dietaryRequirements: [
      ...burger.dietaryRequirements,
    ],
    dietary: [
      ...burger.dietaryRequirements,
    ].map((dietary) =>
      DIETARY_MAPPINGS.hasOwnProperty(dietary)
        ? DIETARY_MAPPINGS[dietary]
        : null
    ),
    image: burger.image,
    // 🛑 keywords: burger.Burger.keywords,🛑
    woapLink: burger.venue && `https://visawoap.com/venue/${burger.venue.kickerberryId}/burger`,
    // 🛑 mainProtein: burger.Burger.what_is_the_main_protein_of_your_burger,
    mainProtein: burger.mainProtein,
    // 🛑 address1: burger.Venue.address1,
    address1: burger.venue.address1,
    // 🛑 address2: burger.Venue.address2,
    address2: burger.venue.address2,
    // 🛑 suburb: burger.Venue.suburb,
    // 🛑 fb: burger.Venue.facebook,
    // 🛑 ig: burger.Venue.instagram,
    // 🛑 tt: burger.Venue.twitter,

    ll: burger.venue.coordinates,
    venueTitle: burger.venue && burger.venue.name,
    venueDescription: burger.venue.description,
    venueImage: burger.venue.image,
    venueWebsite: burger.venue.website?.replace('https://', ''),// && (burger.venue.website.indexOf('https://') === -1) ? `https://${burger.venue.website}` : burger.venue.website,//burger.venue.website,
    // venueFacebook: burger.venue.facebook,
    venueGooglePlacesId: burger.venue.googlePlacesId,
    venueInstagram: burger.venue.instagram?.replace('https://www.instagram.com/', '')?.replace('www.instagram.com/', ''),// && (burger.venue.instagram.indexOf('instagram.com/') === -1) ? `https://instagram.com/${burger.venue.instagram}` : burger.venue.instagram,
    venueTiktok: burger.venue.tiktok?.replace('https://www.tiktok.com/', '')?.replace('www.tiktok.com/', ''),// && (burger.venue.tiktok.indexOf('tiktok.com/') === -1) ? `https://tiktok.com/${burger.venue.tiktok}` : burger.venue.tiktok,//burger.venue.tiktok,
    venueOpeningHours: burger.venue.openingHours && burger.venue.openingHours.weekday_text,
    venueFacebook: burger.venue.facebook?.replace('https://www.facebook.com/', '')?.replace('www.facebook.com/', ''),// && (burger.venue.facebook.indexOf('facebook.com/') === -1) ? `https://facebook.com/${burger.venue.facebook}` : burger.venue.facebook,

    venuePhone: burger.venue.phone,
    venueAccessible: burger.venue.wheelchairAccessible,

    // 🛑 okWithSharing: burger.Burger.are_you_happy_for_customers_to_share_burgers === "Yes", //strings
    // 🛑 allowsBookings: burger.Venue.bookings === '1', 
    // 🛑 wheelchairAccessible: burger.Venue.wheelchair_access && burger.Venue.wheelchair_access.indexOf("Yes") !== -1, //is array of options
  };
});
// setAllBurgers(ALL_BURGERS_SEED);

function App(props) {
  const [myCoords, setMyCoords] = useState(LOCATIONS["⭐ whitcouls elevator between lambton and terrace"]);
  const [allBurgers, setAllBurgers] = useState(ALL_BURGERS_SEED);
  const [filteredBurgers, setFilteredBurgers] = useState([]);
  const [favouriteBurgers, setFavouriteBurgers] = useState([]);
  const [groupFavouriteBurgers, setGroupFavouriteBurgers] = useState([]);
  const [favourites, setFavourites] = useState(attemptToReadStoredFavourites()); //useState(Array(256).fill(undefined));
  const [groupFavourites, setGroupFavourites] = useState(attemptToReadStoredGroupFavourites()); //useState(Array(256).fill(undefined));
  const [preferences, setPreferences] = useState({
    // dietary: {
    dairyFree: false,
    glutenFree: false,
    nutFree: false,
    vegan: false,
    vegetarian: false,
    // },
    // protein: {
    alternative: false,
    beef: false,
    chicken: false,
    duck: false,
    fish: false,
    lamb: false,
    other: false,
    pork: false,
    seafood: false,
    sweet: false,
    veggie: false,
    venison: false,
    //}
    location: "near The Bucket Fountain",
    // venue: {
    allowsBookings: false,
    okWithSharing: false,
    wheelchairAccessible: false,
    // },
    showOnly: undefined,
    sortBy: "distance",
  });

  

  const clearAndReload = () => {
    localStorage.removeItem('bbbName')
    localStorage.removeItem('bbbGroupFavourites')
    localStorage.removeItem('bbbMyFavourites')

    window.location.reload(); 
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    // console.log("preferences", preferences);
    switch (preferences.location) {
      case "near me":
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            setMyCoords([pos.coords.latitude, pos.coords.longitude]);
          },
          (err) => {
            console.warn(`ERROR(${err.code}): ${err.message}`);
          },
          geolocationOptions
        );
        break;
      default:
        break;
    }
  }, [preferences]);

  useEffect(() => {
    // console.log("myCoords", myCoords);
  }, [myCoords]);

  // useEffect(() => {
  //   setGroupFavourites(attemptToReadStoredGroupFavourites());
  //   setFavourites(attemptToReadStoredFavourites());
  // }, []);

  useEffect(() => {
    // console.log("filteredBurgers", filteredBurgers);
  }, [filteredBurgers]);

  useEffect(() => {
    // const newFavouritesString = favouritesToString(favourites);
    localStorage.setItem("bbbMyFavourites", JSON.stringify(favourites));
    // console.log('newFavouritesString', newFavouritesString.length, newFavouritesString);
  }, [favourites]);

  useEffect(() => {
    // console.log("groupFavourites", groupFavourites);
    if (groupFavourites !== null && Object.keys(groupFavourites).length) {
      // localStorage.setItem(
      //   "bbbGroupFavourites",
      //   groupFavouritesToString(groupFavourites)
      // );
      localStorage.setItem(
        "bbbGroupFavourites",
        JSON.stringify(groupFavourites)
      );
    }
  }, [groupFavourites]);



  const [containerSize, containerRef] = useContainerSize();

  function degToRad(degrees) {
    return degrees * (Math.PI / 180);
  }

  /*
  =ACOS(
   COS(RADIANS(90-A2))
   *COS(RADIANS(90-$R$1))
   +SIN(RADIANS(90-A2))
   *SIN(RADIANS(90-$R$1))
   *COS(RADIANS(B2-$S$1))
   ) *6371
  */
  const distanceFunction = ({latitude, longitude}) => {
    // if (!llArr.length) return 99999;
    // const [lat, lon] = llArr.split(",");

    const distance =
      Math.acos(
        Math.cos(degToRad(90 - latitude)) * Math.cos(degToRad(90 - myCoords[0])) +
          Math.sin(degToRad(90 - latitude)) *
            Math.sin(degToRad(90 - myCoords[0])) *
            Math.cos(degToRad(longitude - myCoords[1]))
      ) *
      6371 *
      1000;

    return Math.round(distance);
  };

  useEffect(() => {
    // console.time('apply filters');
    const tempBurgers = ALL_BURGERS_SEED.map((burger) => {
      return {
        ...burger,
        distance: distanceFunction(burger.ll),

        isFavourite: favourites?.find(
          (favourite) => favourite && favourite.bbbidx === burger.bbbidx
        )?.isFavourite,
        isNotInterested: favourites?.find(
          (favourite) => favourite && favourite.bbbidx === burger.bbbidx
        )?.isNotInterested,
        favouritedBy: groupFavourites && Object.keys(groupFavourites).filter(groupFavouritesKey => groupFavourites[groupFavouritesKey].find(groupFavouritesBurger => groupFavouritesBurger.bbbidx === burger.bbbidx)?.isFavourite)//,
      };
    })
    .sort((firstEl, secondEl) => {
      switch (preferences.sortBy) {
        case "price":
          return firstEl.price < secondEl.price ? -1 : 1;
        case "distance":
        default:
          return firstEl.distance < secondEl.distance ? -1 : 1;
      }
    });
    // console.time('filter');
    const tempFilteredBurgers = tempBurgers.filter((burger) => {
      return (
        (!preferences.okWithSharing || burger.okWithSharing) &&
        (!preferences.allowsBookings || burger.allowsBookings) &&
        (!preferences.wheelchairAccessible || burger.wheelchairAccessible) &&
        (!preferences.dairyFree ||
          burger.dietaryRequirements.indexOf("Dairy Free") !== -1 ||
          burger.dietaryRequirements.indexOf("Dairy Free_possible") !== -1) &&
        (!preferences.glutenFree ||
          burger.dietaryRequirements.indexOf("Gluten Free") !== -1 ||
          burger.dietaryRequirements.indexOf("Gluten Free_possible") !== -1) &&
        (!preferences.nutFree ||
          burger.dietaryRequirements.indexOf("Nut Free") !== -1 ||
          burger.dietaryRequirements.indexOf("Nut Free_possible") !== -1) &&
        (!preferences.vegan ||
          burger.dietaryRequirements.indexOf("Vegan") !== -1 ||
          burger.dietaryRequirements.indexOf("Vegan_possible") !== -1) &&
        (!preferences.vegetarian ||
          burger.dietaryRequirements.indexOf("Vegetarian") !== -1 ||
          burger.dietaryRequirements.indexOf("Vegetarian_possible") !== -1) &&
        (preferences.showOnly !== "favourites" || burger?.isFavourite) &&
        (preferences.showOnly !== "group-favourites" ||
          burger?.favouritedBy?.length > 0) &&
        (preferences.showOnly !== "not-yet-rated" ||
          (!burger?.isFavourite && !burger?.isNotInterested)) &&
        (!preferences.alternative || burger.mainProtein.indexOf("Alternative meat") !== -1) &&
        (!preferences.beef || burger.mainProtein.indexOf("Beef") !== -1) &&
        (!preferences.chicken || burger.mainProtein.indexOf("Chicken") !== -1) &&
        (!preferences.duck || burger.mainProtein.indexOf("Duck") !== -1) &&
        (!preferences.fish || burger.mainProtein.indexOf("Fish") !== -1) &&
        (!preferences.lamb || burger.mainProtein.indexOf("Lamb") !== -1) &&
        (!preferences.other || burger.mainProtein.indexOf("Other") !== -1) &&
        (!preferences.pork || burger.mainProtein.indexOf("Pork") !== -1) &&
        (!preferences.seafood || burger.mainProtein.indexOf("Seafood") !== -1) &&
        (!preferences.sweet || burger.mainProtein.indexOf("Sweet/Dessert") !== -1) &&
        (!preferences.veggie || burger.mainProtein.indexOf("Veggie") !== -1) &&
        (!preferences.venison || burger.mainProtein.indexOf("Venison") !== -1)
      );
    });
    // console.timeEnd("filter");
    const tempFavouriteBurgers = tempBurgers
      .filter((burger) => {
        return burger?.isFavourite;
      });
    const tempGroupFavouriteBurgers = tempBurgers
      .filter((burger) => {
        return burger?.favouritedBy?.length > 0;
      })
      .sort((firstEl, secondEl) => {
        if (firstEl?.favouritedBy?.length > secondEl?.favouritedBy?.length) return -1;
        if (firstEl?.favouritedBy?.length < secondEl?.favouritedBy?.length) return 1;

        switch (preferences.sortBy) {
          case "price":
            return firstEl.price < secondEl.price ? -1 : 1;
          case "distance":
          default:
            return firstEl.distance < secondEl.distance ? -1 : 1;
        }
      });
    // console.log(tempFilteredBurgers);
    // window.tempFilteredBurgers = tempFilteredBurgers
    setAllBurgers(tempBurgers);
    setFilteredBurgers(tempFilteredBurgers);
    setFavouriteBurgers(tempFavouriteBurgers);
    setGroupFavouriteBurgers(tempGroupFavouriteBurgers);
    // console.timeEnd('apply filters');
  }, [ALL_BURGERS_SEED, groupFavourites, favourites, preferences, myCoords]);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [myName, setMyName] = React.useState(attemptToReadStoredName());

  
  useEffect(() => {
    localStorage.setItem("bbbName", myName || '');
  }, [myName]);

  const handleNameChange = (e) => {
    setMyName(e.target.value);
  }

  function BurgerGrid({ data }) {
    const COLUMN_WIDTH = 345
    const ROW_HEIGHT = 480
    const NUMBER_OF_COLUMNS = Math.min(Math.floor(containerSize.width / COLUMN_WIDTH), 5) || 1;

    return (

      <div className={classes.listWrapper}>

        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeGrid
              columnCount={NUMBER_OF_COLUMNS}
              columnWidth={(NUMBER_OF_COLUMNS === 1) ? width : COLUMN_WIDTH}
              height={height}
              rowCount={Math.ceil(data.length / NUMBER_OF_COLUMNS)}
              rowHeight={ROW_HEIGHT}
              width={width}
              // style={{marginLeft: 0}}
            >

              {({ columnIndex, rowIndex, style }) => (
                <div style={style}>
                  {data[rowIndex * NUMBER_OF_COLUMNS + columnIndex] ?
                  <BurgerCard
                    burger={data[rowIndex * NUMBER_OF_COLUMNS + columnIndex]}
                    setFavourites={setFavourites}
                    groupFavourites={groupFavourites}
                  />
                  :
  <></>}
                </div>
              )}
            </FixedSizeGrid>
          )}
        </AutoSizer>
      </div>
    );
  }

  let query = useQuery();

  return (
    <div className={classes.root}>
      <BurgerNav
        totalCount={allBurgers.length || 0}
        filteredCount={filteredBurgers?.length || 0}
        locations={LOCATIONS}
        setMyCoords={setMyCoords}
        favourites={favourites}
        groupFavouriteBurgersLength={groupFavouriteBurgers?.length || 0}
        setPreferences={setPreferences}
        preferences={preferences}
      />

      <main className={classes.content} ref={containerRef}>
        <div className={classes.toolbar} />

        <Switch>
          <Route exact path="/">
            <Card>
              <CardContent>
                  <Typography variant="h3" align="center">🍔🍔🍔</Typography>
                <Typography variant="h5">
                  <Link
                    to="/all-burgers"
                    variant="body2"
                    onClick={() => {
                      setPreferences((curPreferences) => {
                        let newPreferences = { ...curPreferences };
                        newPreferences["location"] = "near me";
                        return newPreferences;
                      });
                    }}
                  >
                    see all burgers near you 👀
                  </Link>{" "}
                  or{" "}
                  <Link to="/bst" variant="body2">
                    use the burger selection tool 🔍
                  </Link>{" "}
                  when done{" "}
                  <Link to="/my-favourites" variant="body2">
                    check your favourites ❤️
                  </Link>{" "}
                  and{" "}
                  <Link
                    // component="button"
                    to="/invite"
                    variant="body2"
                  >
                    share them with friends 🔗
                  </Link>{" "}
                  then{" "}
                  <Link
                    // component="button"
                    to="/my-group-favourites"
                    variant="body2"
                  >
                    see which burgers you and your friends like ✨
                  </Link>{" "}
                  and enjoy!{" "}
                  <a
                    // component="button"
                    // to="/say-something-nice"
                    variant="body2"
                    target="_blank"
                    rel="noopener noreferrer"
                    // href="https://www.linkedin.com/posts/oleg-voronin-ab4aa5117_for-people-outside-of-wellington-im-delighted-activity-6828418027818762241-nQY1"
                    href="https://www.linkedin.com/in/oleg-voronin-ab4aa5117/"
                    >
                    say something nice 👋
                  </a>{" "}
                  and{" "}
                  <a
                    // component="button"
                    // to="/kaibosh"
                    variant="body2"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://givealittle.co.nz/fundraiser/burgerburgerburgercom-for-kaibosh-2023"
                  >
                    support kaibosh 💰
                  </a>
                </Typography>
              </CardContent>
            </Card>
          </Route>
          <Route exact path="/bst">
            <BurgerSelectionTool
              preferences={preferences}
              setPreferences={setPreferences}
              key={JSON.stringify(preferences)}
            />
          </Route>
          <Route exact path="/all-burgers">
            <BurgerGrid data={allBurgers}></BurgerGrid>
          </Route>
          <Route exact path="/filtered-burgers">
            {filteredBurgers.length === 0 ? (
              // <Box m={2}>
              <Card>
                <CardContent>
                  <Typography variant="h3" align="center">🍔🍔🍔</Typography>
                  <Typography>
                    Looks like there aren't burgers matching these filters! Use the{" "}
                    <Link to="/bst">burger selection tool</Link> to decide what
                    you're after.
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <BurgerGrid data={filteredBurgers}></BurgerGrid>
            )}
          </Route>
          <Route exact path="/my-favourites">
            {favouriteBurgers.length === 0 ? (
              // <Box m={2}>
              <Card>
                <CardContent>
                  <Typography variant="h3" align="center">🍔🍔🍔</Typography>
                  <Typography>
                    Looks like you don't have any favourites yet! Use the{" "}
                    <Link to="/bst">burger selection tool</Link> to decide what
                    you're after.
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <BurgerGrid data={favouriteBurgers}></BurgerGrid>
            )}
          </Route>
          <Route exact path="/my-group-favourites">
            {groupFavouriteBurgers.length === 0 ? (
              <Card>
                <CardContent>
                  <Typography variant="h3" align="center">🍔🍔🍔</Typography>
                  <Typography>
                    Looks like your friends didn't share their favourites with
                    you yet! Do you want to{" "}
                    <Link to="/share-2023/?oleg-voronin=👄🍔💔💨🍔🍔🍤🍜👄🍦🍔🍴🍴🍔🍗🍔🍔👖👄🍤🍦👄🍕💥🍔🍔">
                      be my burger friend
                    </Link>
                    ?
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <BurgerGrid data={groupFavouriteBurgers}></BurgerGrid>
            )}
          </Route>
          <Route exact path="/invite">
            <Card>
              <CardContent>
                <FormGroup>
                  <Box mb={2}>
                    <Typography>
                      🍔🍔🍔 are more fun with people! Share your favourites
                      with others and enjoy the burgers everyone wants to try
                      together.
                    </Typography>
                    {/* <Typography style={{whiteSpace: 'pre'}}>
                      { emojiAlphabet.join('').match(/.{32}/g).join('\r\n\r\n') }
                    </Typography> */}
                  </Box>
                  <FormControl component="fieldset">
                    <TextField
                      autoComplete="name nickname"
                      label="What's your preferred name?"
                      style={{ margin: 8 }}
                      // placeholder="Something nice"
                      helperText="So people you share this with know who you are!"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // variant="outlined"
                      type="text"
                      value={myName}
                      onChange={handleNameChange}
                    />
                    <TextField
                      autoComplete="off"
                      multiline
                      label="Link to share *your* favourites"
                      style={{ margin: 8 }}
                      placeholder="Please enter your name above ^^^"
                      helperText="No registration, all details are part of
                      the link."
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <CopyToClipboard
                            text={
                              !!myName
                                ? `https://www.burgerburgerburger.com/share-2023/?${
                                    myName
                                      .toLowerCase()
                                      .replace(" ", "-")
                                      .match(/[a-z0-9-]*/)[0]
                                  }=${favouritesToString(favourites)}`
                                : ""
                            }
                            onCopy={() => {
                              /*this.setState({copied: true})*/
                            }}
                          >
                            <Button color="secondary" disabled={!myName}>
                              Copy
                            </Button>
                          </CopyToClipboard>
                        ),
                      }}
                      // variant="outlined"
                      disabled={!myName}
                      value={
                        !!myName
                          ? `https://www.burgerburgerburger.com/share-2023/?${
                              myName
                                .toLowerCase()
                                .replace(" ", "-")
                                .match(/[a-z0-9-]*/)[0]
                            }=${favouritesToString(favourites)}`
                          : ""
                      }
                    />
                    <TextField
                      autoComplete="off"
                      multiline
                      label="Link to share *your group* favourites"
                      style={{ margin: 8 }}
                      placeholder={
                        groupFavourites === null ||
                        Object.keys(groupFavourites).length === 0
                          ? `No group favourites to share`
                          : `Please enter your name above ^^^`
                      }
                      helperText="No registration, all details are part of
                      the link."
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <CopyToClipboard
                            text={
                              !(
                                !myName ||
                                groupFavourites === null ||
                                Object.keys(groupFavourites).length === 0
                              )
                                ? `https://www.burgerburgerburger.com/share-2023/?${groupFavouritesToString(
                                  {...groupFavourites, ...{[myName
                                    .toLowerCase()
                                    .replace(" ", "-")
                                    .match(/[a-z0-9-]*/)[0]]: favourites}}
                                  )}`
                                : ""
                            }
                            onCopy={() => {
                              /*this.setState({copied: true})*/
                            }}
                          >
                            <Button color="secondary" disabled={!myName}>
                              Copy
                            </Button>
                          </CopyToClipboard>
                        ),
                      }}
                      // variant="outlined"
                      disabled={
                        !myName ||
                        groupFavourites === null ||
                        Object.keys(groupFavourites).length === 0
                      }
                      value={
                        !(
                          !myName ||
                          groupFavourites === null ||
                          Object.keys(groupFavourites).length === 0
                        )
                          ? `https://www.burgerburgerburger.com/share-2023/?${groupFavouritesToString(
                              {...groupFavourites, ...{[myName
                                .toLowerCase()
                                .replace(" ", "-")
                                .match(/[a-z0-9-]*/)[0]]: favourites}}
                            )}`
                          : ""
                      }
                    />
                  </FormControl>
                  <Box mt={4} style={{}}>
                    <Typography>
                      When someone follows your link, they'll be able to import
                      your favourites…
                    </Typography>
                    <img
                      src="/share-step-1.png"
                      alt="import group favourites"
                      style={{ width: "100%", maxWidth: 320, opacity: 0.5 }}
                    />
                    <Typography>…and see who wants to try what!</Typography>
                    <img
                      src="/share-step-2.png"
                      alt="see group favourites"
                      style={{ width: "100%", maxWidth: 320, opacity: 0.5 }}
                    />
                  </Box>
                </FormGroup>
              </CardContent>
            </Card>
            {/* </Box> */}
          </Route>
          <Route exact path="(/share-2023/|/share-v2/)">
            <ReceiveInvite
              setFavourites={setFavourites}
              setGroupFavourites={setGroupFavourites}
              groupFavourites={groupFavourites}
              myNameSlug={
                myName
                  .toLowerCase()
                  .replace(" ", "-")
                  .match(/[a-z0-9-]*/)[0]
              }
            />
          </Route>
          <Route exact path="(/download)">
            <Box m={2}>
            <Typography>Download as a basic spreadsheet, but only on a desktop because [technical excuse].</Typography>
                <FormGroup>
                  
                  <DownloadCSVButton burgerData={favouriteBurgers} fileName="burgers-favourites.csv" name={`download favourites${favouriteBurgers.length ? ` (${favouriteBurgers.length})` : ' - none selected'}`} disabled={favouriteBurgers.length === 0}
                    variant="contained"/>
                  <DownloadCSVButton burgerData={allBurgers} fileName="burgers-all.csv" name={`download all burgers (${allBurgers.length})`} color="tertiary" 
                    variant="contained"/>
                </FormGroup>
              </Box>
            
          </Route>
          <Route exact path="/settings">
            <Box m={2}>
            <Typography>Everything burgerburgerburger needs to run is stored on your device. Google Analytics can be disabled with an adblocker.</Typography>
                <FormGroup>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={clearAndReload}
                  >
                    Clear local data and reload
                  </Button>
                </FormGroup>
              </Box>
          </Route>
          <Route exact path="/404/">
            <Typography variant="h3" align="center">¯\_(ツ)_/¯</Typography>
            <Typography align="center">404 not found</Typography>
          </Route>
          <Redirect to="/404" />
        </Switch>
      </main>
    </div>
  );

  function DownloadCSVButton({ burgerData, fileName, name, ...props }) {
    const jsonData = burgerData.map(favourite => ({
      name: favourite.name,
      price: favourite.price,
      beerMatch: favourite.beerMatch,
      beerMatchPrice: favourite.beerMatchPrice,
      dietaries: favourite.dietaryRequirements.map((dietary) =>
        dietary.toLowerCase().replace("_", " ")
      ).join(', '),
      protein: favourite.mainProtein,
      description: favourite.burgerDescription,
      link: favourite.woapLink,
      venue: favourite.venueTitle,
      address: favourite.address1,
      distance: favourite.distance,
    }))
    const handleDownload = () => {
      const csvContent = [
        '\ufeff', // Byte Order Mark (BOM) to indicate UTF-8 encoding
        Object.keys(jsonData[0]).map(header => `="${header}"`).join(','),
        ...jsonData.map((row) =>
          Object.values(row)
            .map((value) => {
              if (typeof value === 'string') {
                return `"${value.replace(/\s+/g, ' ').replace(/"/g, '""')}"`; // Enclose value in double quotes and escape any existing quotes
              }
              return value;
            })
            .join(',')
        ),
      [""],
      ["support burgerburgerburger.com fundraiser for Kaibosh","🍔🍔🍔","https://givealittle.co.nz/fundraiser/burgerburgerburgercom-for-kaibosh-2023",]
      ].join('\n');

      // const csvContent = [
      //   Object.keys(jsonData[0]).join(','),
      //   // ...jsonData.map((row) => Object.values(row).join(',')),
      //   ...jsonData.map((row) =>
      //   Object.values(row)
      //     .map((value) => {
      //       if (typeof value === 'string' && (value.includes(',') || value.includes('\n'))) {
      //         return `"${value}"`; // Enclose value in double quotes if it contains a comma
      //       }
      //       return value;
      //     })
      //     .join(',')
      //   ),
      // ].join('\n');
  
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      window.location.reload(); 
    };
  return <Button
    variant="contained"
    color="secondary"
    onClick={handleDownload}
    component={Link}
    {...props}
  >
    {name}
  </Button>
  }

  function useContainerSize() {
    const containerRef = useRef(null);
    const [size, setSize] = useState({ width: 0, height: 0 });
  
    useLayoutEffect(() => {
      function updateSize() {
        if (containerRef.current) {
          const width = containerRef.current.offsetWidth;
          const height = containerRef.current.offsetHeight;
          // const scrollbarWidth = width - containerRef.current.clientWidth;
          const scrollbarWidth = 16;
          setSize({
            width: width - scrollbarWidth,
            height: height,
          });
        }
      }
  
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
  
    return [size, containerRef];
  }
}

export default App;

import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useCardStyles = makeStyles((theme) => ({
  body2: {
    "& + &": {
      marginTop: theme.spacing(1),
    },
  },
  root: {
    "& + &": {
      //   marginTop: theme.spacing(2),
    },
    // maxWidth: 345,
    maxWidth: 390,
    // maxWidth: 333,
    // maxHeight: 450,
    minHeight: 480 - theme.spacing(2),
    height: 480 - theme.spacing(2),
    position: "relative",
    paddingBottom: "64px",
    margin: theme.spacing(1),
    // marginBottom: theme.spacing(2),
    // marginRight: theme.spacing(2),
    // marginLeft: theme.spacing(2),
    // marginTop: theme.spacing(2),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
  },
  rootExpanded: {
    height: "auto",
    zIndex: 999,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    "img&": {
      height: "auto",
      paddingTop: 0,
    },
  },
  mediaWrapper: {
    position: "relative",
  },
  dietaryAvatarGroup: {
    position: "absolute",
    // marginTop: -theme.spacing(2),
    marginTop: 0,
    left: theme.spacing(2),
    // zoom: "67%",
    transform: `scale(0.75) translate(0, -50%)`,
    transformOrigin: `left top`,
  },
  dietaryAvatar: {
    background: "#555",
  },
  favouritedByAvatarGroup: {
    position: "absolute",
    bottom: 0,
    // bottom: -theme.spacing(2),
    // marginTop: -theme.spacing(2),
    right: theme.spacing(2),
    // height: "20px",
    // zoom: "67%",
    transform: `scale(0.75) translate(0, 50%)`,
    transformOrigin: `right bottom`,
  },
  favouritedByAvatar: {
    background: "#555",
    borderRadius: "100%",
    // background: "#f50057",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardActions: {
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    // background: "linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,.85) 10%)",
    background: "rgba(255,255,255,.8)",
    backdropFilter: "blur(2px);",
  },
  cardContent: {
    // margin: 0,
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    // paddingTop: 0,
  },
}));

const BurgerCard = ({ burger, setFavourites, groupFavourites }, ...props) => {
  const classes = useCardStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleClickAway = () => {
    setExpanded(false);
  };

  const handleFavouriteClick = (bbbidx, currentValue) => {
    setFavourites((curFavourites) => {
      let newFavourites = [...curFavourites];
      newFavourites[bbbidx] = {
        bbbidx: bbbidx,
        isFavourite: !currentValue,
        isNotInterested: false,
      };

      return newFavourites;
    });
  };

  const handleNotInterestedClick = (bbbidx, currentValue) => {
    setFavourites((curFavourites) => {
      let newFavourites = [...curFavourites];
      newFavourites[bbbidx] = {
        bbbidx: bbbidx,
        isFavourite: false,
        isNotInterested: !currentValue,
      };

      return newFavourites;
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Card
        className={
          expanded ? `${classes.root} ${classes.rootExpanded}` : classes.root
        }
        raised={expanded}
      >
        
        <CardHeader
          title={
            <>
              {burger.name}
              {/* #{burger.bbbidx} */}
              {` ($${burger.price})`}
            </>
          }
          subheader={
            <>
              <a
                href={burger.venueGooglePlacesId ? `https://www.google.com/maps/place/?q=place_id:${burger.venueGooglePlacesId}` : `https://www.google.co.nz/maps?q=${burger.ll.latitude},${burger.ll.longitude}}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {burger.venueTitle}
                {` (${burger.distance}m)`}
              </a>
            </>
          }
        />
        <div className={classes.mediaWrapper}>
          {burger.dietary.length ? (
            <AvatarGroup className={classes.dietaryAvatarGroup}>
              {burger.dietary.map((dietary) => (
                <Avatar key={dietary} className={classes.dietaryAvatar}>
                  {dietary}
                </Avatar>
              ))}
            </AvatarGroup>
          ) : null}
          <CardMedia
            className={classes.media}
            image={burger.image}
            title={burger.name}
          />
          {burger?.favouritedBy?.length ? (
            <AvatarGroup max={11} className={classes.favouritedByAvatarGroup}>
              {burger.favouritedBy.map((friend) => (
                <Badge
                  key={friend}
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  className={classes.favouritedByAvatar}
                  badgeContent={<FavoriteIcon color={"secondary"} />}
                >
                  <Avatar
                    alt={friend}
                    key={friend}
                    className={classes.favouritedByAvatar}
                  >
                    {friend
                      .split("-")
                      .map((substr) => substr[0])
                      .join("")
                      .toUpperCase()}
                  </Avatar>
                </Badge>
              ))}
            </AvatarGroup>
          ) : null}
        </div>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" color="textSecondary" component="p">
            {/* {JSON.stringify(burger)} */}
            <Box mb={1}>{burger.burgerDescription}</Box>
            <Box my={1}>
              {burger.mainProtein && `Main protein: ${(burger.mainProtein).toLowerCase()}.`}
              <br/>
              {burger.dietary && burger.dietary.length ? (
                <>
                  Dietary options:&nbsp;
                  {burger.dietaryRequirements.map((dietary) =>
                    dietary.toLowerCase().replace("_", " ")
                  ).join(', ')}
                  .
                </>
              ) : (
                <>Cannot cater to dietary requirements.</>
              )}
            </Box>

            <hr/>

            {burger.woapLink && <Box my={1}>
              <a
                href={burger.woapLink}
                target="_blank"
                rel="noreferrer noopener"
              >
                official event link
              </a>
            </Box>}
            <hr/>

            <Typography variant="h6">
            {burger.venueTitle}
            </Typography>
            
            <Box my={1}>
              <a
                href={burger.venueGooglePlacesId ? `https://www.google.com/maps/place/?q=place_id:${burger.venueGooglePlacesId}` : `https://www.google.co.nz/maps?q=${burger.ll.latitude},${burger.ll.longitude}}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {burger.address1}
                {burger.address2 && `, ${burger.address2}`}
              </a>

            </Box>

            {/* {burger.allowsBookings ? <Box my={1}>
              They should be able to take bookings, be kind!
            </Box> : <Box my={1}>
              They won't be able to take bookings, be kind!
            </Box>} */}
            {burger.venueAccessible ? <Box my={1}>
              They should be wheelchair accessible.
            </Box> : <Box my={1}>
              They may not be wheelchair accessible.
            </Box>}
            {/* {burger.okWithSharing && <Box my={1}>
              While they should be happy for customers to share burgers, remember to be nice about it!
            </Box>} */}

            
            {burger.venueFacebook && <Box my={1}>
              <a
                href={`https://www.facebook.com/${burger.venueFacebook}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                FB: {burger.venueFacebook}
              </a>
            </Box>}
            {burger.venueInstagram && <Box my={1}>
              <a
                href={`https://www.instagram.com/${burger.venueInstagram}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Insta: {burger.venueInstagram}
              </a>
            </Box>}
          
            {burger.venueTiktok && <Box my={1}>
              <a
                href={`https://www.tiktok.com/${burger.venueTiktok}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {burger.venueTiktok}
              </a>
            </Box>}
            {burger.venueWebsite && <Box my={1}>
              <a
                href={`https://${burger.venueWebsite}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {burger.venueWebsite}
              </a>
            </Box>}
            {burger.venuePhone && <Box my={1}>
              <a
                href={`tel:${burger.venuePhone}`}
              >
                {burger.venuePhone}
              </a>
            </Box>}
            
            {burger.venueOpeningHours && burger.venueOpeningHours.length && <Box my={1}>
              Opening Hours:
              <ul>
                {burger.venueOpeningHours.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Box>}

            
            <CardMedia
              className={classes.media}
              image={burger.venueImage}
              title={burger.venueTitle}
            />
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <IconButton
            aria-label="not interested"
            onClick={() =>
              handleNotInterestedClick(burger.bbbidx, burger.isNotInterested)
            }
            disableRipple
          >
            <NotInterestedIcon
              color={burger.isNotInterested ? "secondary" : "action"}
            />
          </IconButton>
          <IconButton
            className={
              expanded
                ? `${classes.expand} ${classes.expandOpen}`
                : `${classes.expand}`
            }
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            disableRipple
          >
            <ExpandMoreIcon />
          </IconButton>

          <IconButton
            aria-label="add to favorites"
            onClick={() =>
              handleFavouriteClick(burger.bbbidx, burger.isFavourite)
            }
            disableRipple
          >
            <FavoriteIcon color={burger.isFavourite ? "secondary" : "action"} />
          </IconButton>
        </CardActions>
      </Card>
    </ClickAwayListener>
  );
};

export default BurgerCard;
